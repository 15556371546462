<template>
  <v-tooltip
    dense
    class="pa-0"
    :disabled="!show_name_in_tooltip"
    :color="tooltipComputedColor"
    light
    :bottom="tooltip_direction_bottom"
    :top="tooltip_direction_top"
    :left="tooltip_direction_left"
    :right="tooltip_direction_right"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        :rounded="rounded"
        v-bind="attrs"
        v-on="on"
        :size="size"
        class="avatar"
      >
        <v-img
          class="userAvatarImg"
          :lazy-src="defaultAvatar"
          v-bind:class="{ imgBorder: avatar_border }"
          :src="avatarUrl"
        >
          <slot name="imgSlot"></slot>
        </v-img>
      </v-avatar>
    </template>
    <span class="caption white--text">{{
      first_name || last_name ? name : email
    }}</span>
    <br />
    <span class="caption white--text">{{ job_title }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    avatar_url: {
      type: String,
      default: "",
    },
    first_name: {
      type: String,
      default: "",
    },
    last_name: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 24,
    },
    show_name_in_tooltip: {
      type: Boolean,
      default: false,
    },
    avatar_border: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
      required: false,
    },
    job_title: {
      type: String,
      default: "",
      required: false,
    },
    tooltip_direction_bottom: {
      type: Boolean,
      default: true,
    },
    tooltip_direction_top: {
      type: Boolean,
      default: false,
    },
    tooltip_direction_left: {
      type: Boolean,
      default: false,
    },
    tooltip_direction_right: {
      type: Boolean,
      default: false,
    },
    tooltip_color: {
      type: String,
      default: "button_blue",
    },
    email: {
      type: String,
      default: "",
    },
  },
  computed: {
    tooltipComputedColor() {
      if (!this.first_name && !this.last_name) {
        return "#" + this.HSLToHex(this.stringToHslColor(this.email, 30, 65));
      }
      return (
        "#" +
        this.HSLToHex(
          this.stringToHslColor(this.first_name + this.last_name, 30, 65)
        )
      );
    },
    defaultAvatar() {
      if (!this.first_name && !this.last_name) {
        return (
          "https://eu.ui-avatars.com/api/?name=" +
          this.email +
          "&format=svg&background=" +
          this.HSLToHex(this.stringToHslColor(this.email, 30, 65)) +
          "&color=fff&size=" +
          this.size
        );
      }
      return (
        "https://eu.ui-avatars.com/api/?name=" +
        this.first_name +
        "+" +
        this.last_name +
        "&format=svg&background=" +
        this.HSLToHex(
          this.stringToHslColor(this.first_name + this.last_name, 30, 65)
        ) +
        "&color=fff&size=" +
        this.size
      );
    },
    avatarUrl() {
      if (this.avatar_url) {
        return this.avatar_url;
      } else {
        return this.defaultAvatar;
      }
    },
    name() {
      return [this.first_name, this.last_name].join(" ");
    },
  },
  methods: {
    stringToHslColor(str, s, l) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash; //makes sure we only return a 32 bit number.
      }

      var h = hash % 360;

      return [
        this.range(hash, 0, 360),
        this.range(hash, 30, 60),
        this.range(hash, 55, 70),
      ];
    },
    HSLToHex(hsl) {
      let h = hsl[0];
      let s = hsl[1];
      let l = hsl[2];

      var hslToHex = require("hsl-to-hex");

      return hslToHex(h, s, l).replace("#", "");
    },
    range(hash, min, max) {
      var diff = max - min;
      var x = ((hash % diff) + diff) % diff;
      return x + min;
    },
  },
};
</script>

<style scoped>
.imgBorder {
  border: 2px solid white;
}
</style>

